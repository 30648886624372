var arr = []

var v = [{
  g: "home"
  , title: e('home'),
  route: 'home',
  icon: 'HomeIcon',
},
{
  g: "devices"
  , title: e('devices'),
  route: 'devices',
  icon: 'HardDriveIcon',
},
{
  g: "users"
  , title: e('manage-users'),
  icon: 'UsersIcon',
  tagVariant: 'light-warning',
  children: [
    {
      title: e('view-users'),
      route: 'users',
    },
    {
      title: e('add-user'),
      route: 'users-add',
    },
    {
      title: e('import-users'),
      route: 'users-import',
    },
    {
      title: e('app_devices'),
      route: 'app-devices',
    },
  ],
},
{
  g: "reports"
  , title: e('reports'),
  icon: 'PieChartIcon',
  tagVariant: 'light-warning',
  children: [
    {
      title: e('day-attend-report'),
      route: 'day-report',
    },
    {
      title: e('between-attend-report'),
      route: 'between-report',
    },
    {
      title: e('manual-attend'),
      route: 'manual-attend',
    },
    {
      title: e('salary-report'),
      route: 'salary-report',
    },
    {
      title: e('rejected-reports'),
      route: 'rejected-reports',
    },
    {
      title: e('download-excel-report'),
      route: 'download-excel-report',
    },
  ],
},
{
  g: "freedays"
  , title: e('freedays'),
  route: 'freedays',
  icon: 'CalendarIcon',
},
{
  g: "freedays"
  , title: e('freedays-requests') + ` <span id='freedays-requests'></span>`,
  route: 'freedays-requests',
  icon: 'FileTextIcon',
},
{
  g: "locations"
  , title: e('locations'),
  route: 'locations',
  icon: 'MapIcon',
},
{
  g: "admins"
  , title: e('admins'),
  route: 'admins',
  icon: 'ShieldIcon',
},
{
  g: "settings"
  , title: e('settings'),
  icon: 'SettingsIcon',
  tagVariant: 'light-warning',
  children: [
    {
      title: e('general-settings'),
      route: 'settings',
    },
    {
      title: e('settings-groups'),
      route: 'settings-groups',
    },
  ],
},
{
  g: "all"
  ,
  title: e('pay-or-renew'),
  route: 'pay',
  icon: 'DollarSignIcon',
},
{
  g: "api"
  ,
  title: e('api'),
  route: 'page-api',
  icon: 'TerminalIcon',
},
{
  g: "all"
  , title: e('support'),
  route: 'page-support',
  icon: 'HeadphonesIcon',
},
{
  g: "all"
  , title: e('docs'),
  route: 'page-docs',
  icon: 'VideoIcon',
},
{
  g: "all"
  , title: e('another-services'),
  route: 'page-another-services',
  icon: 'GridIcon',
},]

if (localStorage.getItem('user')) {

  var user = JSON.parse(localStorage.getItem('user'))

  v.forEach(element => {

    var add = false;

    if (user.subuser && user.rules != 'all') {
      if (user.subuser.rules == 'all') {
        add = true
      }
      if (user.subuser.all_rules) {
        add = true;
      } else {
        user.subuser.rules.forEach(rule => {
          if (rule == '/' && element.g == 'home') {
            add = true;
          } else if (element.g.includes(rule)) {
            add = true;
          }
        });
      }
    } else {
      add = true;
    }

    if (add) {
      arr.push(element);
    }

  });

}

export default arr